/* Lato Thin */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap");

/* Lato Regular */
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* Lato Medium */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@500&display=swap");

/* Lato Bold */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato";
  font-size: 16px;
}


.alert-deconnexion {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1002;
  top: 0;
  background-color: rgba(3, 11, 36, 0.781);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-alert {
  width: 350px;
  height: 250px;
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
}

button {
  text-decoration: none;
  box-decoration-break: none;
  border: none;
}

.title {
  font-size: 34px;
  text-align: center;
  margin-bottom: 40px;
}

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-block {
  width: 1200px;
}

.container-xxl {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-fluide {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.container-login {
  width: 450px;
  height: 500px;
  padding: 50px;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 10px;
}

.block {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containt-login-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-60 {
  margin-top: 100px;
}
.mb-30 {
  margin-bottom: 30px;
}

.login-logo {
  width: 100px;
  text-align: center;
}

.text-center {
  text-align: center;
}

.container-login form input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 7px;
  outline: none;
}

.obligatoire {
  color: red;
}

.btn {
  width: 150px;
  height: 40px;
  margin-top: 30px;
  border-radius: 5px;
  color: azure;
  border: none;
  background-color: #4377bc;
  font-size: 16px;
  text-align: center;
}

.block-label {
  margin: 20px 0 5px;
  position: relative;
  font-size: 14px;
}

.block-label-membre {
  position: relative;
  font-size: 14px;
}

.block-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  cursor: pointer;
  width: 150px;
  height: 40px;
  box-shadow: 1px 1px 4px rgb(162, 162, 162);
  background-color: #3953a4;
}
.block-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: rgb(52, 134, 206);
}

.error {
  position: absolute;
  color: red;
  font-size: 12px;
  right: 0;
}

.forgot-password {
  width: 500px;
  font-size: 12px;
  text-align: center;
}

.forgot-password a {
  margin-left: 8px;
}

.nav-shadow {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.164);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.nav-bar {
  width: 1200px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.containt-logo {
  width: 100px;
}
.block-nav {
  width: 900px;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
}
.deconnexion {
  width: 210px;
  display: flex;
  justify-content: space-between;
}

.mr-5 {
  margin-right: 5px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-20 {
  margin-left: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.btn-deconnexion {
  width: 150px;
  height: 40px;
  border-radius: 400px;
  color: azure;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3953a4;
}

.user {
  width: 50px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user a {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  position: absolute;
  width: 655px;
  height: 40px;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav a {
  display: inline-block;
  width: 130px;
  height: 40px;
  border-radius: 100px;
  color: #4377bc;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav a:hover {
  color: white;
  background-color: #4377bc;
}

.nav .active {
  background-color: #4377bc;
  color: aliceblue;
}

.user .notuser {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #3953a4; */
  border: 2px solid #3953a4;
}

.activeUser {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #3953a4;
}

.container-lg {
  width: 1200px;
  height: 60vh;
}

.param-nav {
  width: 440px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageUTL {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  color: azure;
  border: none;
  background-color: #3953a4;
  font-size: 24px;
  text-align: center;
}

.notUTL {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  color: #3953a4;
  border: 4px solid #3953a4;
  background-color: white;
  font-size: 24px;
  text-align: center;
}

.bar {
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
  background-color: rgba(62, 62, 62, 0.292);
}

.block-liste {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  border: 2px solid #4377bc;
}

.liste-title {
  height: 50px;
  color: aliceblue;
  font-size: 24px;
  padding-left: 20px;
  border-radius: 5px 5px 0 0;
  background-color: #4377bc;
  display: flex;
  align-items: center;
}

.liste-containt {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 1180px;
  height: 430px;
  border-radius: 10px;
  border: 1px solid rgb(146, 146, 146);
  background-color: #f4f4f4;
  /* overflow-y: scroll; */
}

.theader {
  height: 32px;
  padding: 8px;
  border-radius: 6px;
  color: aliceblue;
  background-color: rgb(146, 146, 146);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tbody {
  padding: 8px;
  text-align: left;
}

.check {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100 {
  width: 100px;
}
.w-150 {
  width: 150px;
}

.pl-10 {
  padding-left: 10px;
}

.w-300 {
  width: 300px;
}
.w-200 {
  width: 200px;
}

.w-350 {
  width: 350px;
}

.box {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: #f4f4f4;
}

.liste-value {
  height: 40px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-modif {
  width: 630px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-modif-sm {
  width: 470px;
}

.button-modif-xxl {
  width: 1200px;
}

.mt-30 {
  margin-top: 30px;
}

.btn-param {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  color: azure;
  border: none;
  background-color: #4377bc;
  font-size: 16px;
  text-align: center;
}

.display-none {
  display: none;
}

.revoque {
  background-color: #3953a4;
}

.container-form {
  width: 500px;
  height: 600px;
  padding: 50px;
  border: 1px solid grey;
  border-radius: 10px;
}

.block-form {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-form {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  outline: none;
  border: 1px solid grey;
  border-radius: 5px;
}

.input-impot {
  width: 200px;
  height: 40px;
  font-size: 20px;
  font-weight: bolder;
  outline: none;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-image {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  background-image: url("./images/bible.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.verset {
  width: 400px;
  height: 100px;
  margin-top: 500px;
  padding: 10px;
  color: aliceblue;
}

.verset h3 {
  margin-bottom: 20px;
}

.flex-images {
  width: 1200px;
  height: 300px;
  display: flex;
  justify-content: space-between;
}

.cadre-image {
  width: 380px;
  height: 200px;
}

.cretien {
  background-image: url("./images/cretien.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cretiens {
  background-image: url("./images/cretiens.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.header {
  background-image: url("./images/header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.containt-user {
  width: 1200px;
  height: 400px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.photo {
  width: 400px;
  height: 400px;
  padding: 20px;
}

.data-user {
  width: 800px;
  height: 400px;
  padding-left: 100px;
}

.container-fluide-user {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.bar-1200 {
  width: 1200px;
}

.userphoto {
  width: 380px;
  height: 380px;
  background-image: url("./images/header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.success {
  display: inline-block;
  font-size: 14px;
  width: 230px;
  height: 40px;
  margin-left: 15px;
  border: 2px solid rgb(0, 95, 0);
  border-radius: 5px;
  color: white;
  background-color: rgba(29, 145, 29, 0.808);
}

.text-success {
  color: rgba(29, 145, 29, 0.808);
}

/* Membre */
.liste-membre {
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-modif-membre {
  width: 310px;
}

.block-liste-membre {
  height: 770px;
  background-color: rgba(212, 212, 212, 0.265);
}

.block-liste-cherche {
  height: 200px;
  background-color: rgba(212, 212, 212, 0.265);
}

.table-membre {
  width: 1180px;
  height: 700px;
  display: flex;
  flex-direction: column;
}

.data {
  height: 200px;
  margin: 5px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #4377bc;
  background-color: white;
}

.data-containte {
  height: 120px;
  margin-top: 10px;
}

.data-containte-section {
  height: 150px;
}

.input-data {
  width: 250px;
  padding: 10px;
  display: inline-block;
}

.w-small {
  width: 200px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.champ {
  /* width: 340px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  font-size: 24px;
  color: #4377bc;
}

.radio-binary {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 10px;
  color: #4377bc;
  border: 2px solid #4377bc;
  border-radius: 8px;
  background-color: #fff;
}

.radio-binary-lg {
  width: 50px;
  height: 35px;
  font-size: 17px;
}

.cheked {
  color: #fff;
  background-color: #4377bc;
}

.liste-recherche {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(3,auto);
  grid-template-rows: repeat(1,auto);
  gap: 20px;
}

.champ-cherche {
  width: 350px;
  height: 70px;
}

.block-liste-cherche {
  height: 180px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
.section {
  height: 250px;
}

/* Membre ajouter */
.container-form-membre {
  width: 1000px;
  height: 650px;
  padding: 50px;
  border: 1px solid grey;
  border-radius: 10px;
}

.row {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-lg {
  height: 150px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-6 {
  width: 420px;
  height: 60px;
}
.col-6 div {
  position: relative;
  width: 100%;
}

.button-modif-action {
  width: 900px;
  height: 40px;
}

.pagination {
  width: 300px;
  height: 40px;
  position: relative;
}

.right-page {
  width: 150px;
  height: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
}
.pt-5 {
  padding: 4px;
}
.pageNum {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  margin: 0 2px;
  border: none;
  background-color: #4377bc;
}

.blockSection {
  height: 70px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assigne {
  width: 400px;
  height: 70px;
}
.section-status {
  width: 130px;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.container-form-section {
  width: 700px;
  height: 650px;
  padding: 50px;
  border: 1px solid grey;
  border-radius: 10px;
}

.table-membre-users {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.mt--top {
  margin-top: -20px;
}

.data-sm {
  height: auto;
  margin: 5px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #4377bc;
  background-color: white;
}
.data-section-sm {
  height: auto;
  margin: 5px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #4377bc;
  background-color: white;
}

/* cotisation */
.liste-recherche-px {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}

.block-liste-cherche-lg {
  height: 250px;
}
.champ-cherche-sm {
  width: 260px;
  display: flex;
  justify-content: space-between;
}

.champ-cherche-xsm {
  width: 280px;
}

.xsm-block {
  width: 150px;
}

.champ-cherche-lg {
  width: 420px;
  display: flex;
  justify-content: space-between;
}

.champ-cherche-lg div {
  width: 200px;
}
.btn-sm {
  width: 120px;
  height: 40px;
  margin-top: 30px;
  border-radius: 5px;
  color: azure;
  border: none;
  background-color: #4377bc;
  font-size: 16px;
  text-align: center;
}
.btn-sm:hover {
  cursor: pointer;
  width: 120px;
  height: 40px;
  box-shadow: 1px 1px 2px rgb(173, 172, 172);
  background-color: #3953a4;
}

.btn-filter {
  width: 100%;
  height: 40px;
  margin-top: 8px;
}
.block-liste-xsm {
  width: 100%;
  height: 320px;
  border-radius: 10px;
  border: 2px solid #4377bc;
}

.liste-data-culum {
  width: 100%;
  height: 200px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.liste-data-culum-grid {
  display: grid;
  grid-template-columns: repeat(3,350px);
  grid-template-rows: repeat(1,100%);
  gap: 30px;
}
.data-list-culum {
  display: grid;
  grid-template-columns: repeat(1,100%);
  grid-template-rows: repeat(2,auto);
  gap: 10px;
}
.ref-cotisation {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.ref-data {
  width: 250px;
}

.textarea {
  height: 130px;
  padding: 10px;
  display: block;
  font-size: "Lato Thin";
}

.col-lg-6 {
  width: 420px;
  height: 150px;
}

.w-plein {
  width: 100%;
}

.mini-input {
  width: 200px;
}

.block-mini-input {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.w-420 {
  width: 420px;
}

/* loading page */
.loading {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.donateur {
  font-size: 18px;
  margin-left: 20px;
  font-weight: bolder;
  color: gray;
}

.A4pdf1 {
  width: 210mm;
  height: 297mm;
  padding: 10mm;
  box-sizing: border-box;
  /* box-shadow: 2px 2px 8px rgba(2, 2, 2, 0.103); */
}

.bordered {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.sous-error {
  color: #4377bc;
  font-size: 12px;
  text-align: center;
}

.header_recu {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
}

.number_recu {
  width: auto;
  height: auto;
}

.title_recu {
  width: auto;
  height: auto;
  padding-top: 20px;
}

.cerfa {
  width: 140px;
  height: 75px;
  background-image: url("images/cerfa.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.underline {
  text-decoration: underline;
}

.objet {
  font-size: 20px;
  margin-left: 20px;
}

.case {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 20px;
  border-radius: 2px;
  font-size: 18px;
  outline: none;
  border: 1px solid gray;
}
.display-inline {
  display: inline;
}

.mini-text {
  font-size: 8px;
}

.paren-align-case {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.align-case {
  width: 240px;
  height: 50px;
  display: flex;
  align-items: center;
}

.signature {
  width: 100%;
  height: 200px;
  margin-top: 50px;
  position: relative;
}

.deconnexion-responsive {
  display: inline-block;
}

.bloc-signature {
  width: 200px;
  height: auto;
  position: absolute;
  box-sizing: border-box;
  right: 0;
  top: 0;
}

.img-signature {
  width: 180px;
  height: 80px;
  background-image: url("./images/signature.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: content-box;
}

.btn-exporter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.t-medium {
  font-size: 24px;
}

.burger-menu {
  position: relative;
  display: none;
}

.menu-icon {
  display: none;
  width: 35px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.menu-icon div {
  width: 100%;
  height: 5px;
  background-color: #3953a4;
  transition: all 0.3s ease;
}

.menu-icon.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.menu-content {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.menu-content.open {
  display: flex;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #3953a4;
}

.menu-content ul li a {
  text-decoration: none;
  color: #3953a4;
}

.menu-content ul li a:hover {
  background-color: #4377bc;
}

.display-none {
  display: none;
}

.menu-responsive {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dataBox {
  display: grid;
  grid-template-columns: repeat(3,350px);
  grid-template-rows: repeat(2,50px);
  gap: 10px;
}

.dataBox-sm {
  display: grid;
  grid-template-columns: repeat(3,350px);
  grid-template-rows: repeat(4,40px);
  gap: 8px;
}

.responsive-on-600 {
  display: none;
}

.block-liste-cherche-reporting {
  height: auto;
}

.data-containte-user-section {
  height: auto;
}
.data-containte-user-data {
  height: auto;
}
.liste-recherche-user-cotisation {
  display: grid;
  grid-template-columns: repeat(4,290px);
  grid-template-rows: repeat(1,60px);
  gap: 8px;
}
.champ-cherche-xsm-user {
  height: 100%;
}

/* Responsive */
@media (max-width: 1440px) {
  .nav-bar {
    width: 1200px;
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .nav-bar {
    width: 998px;
  }
  .bar-1200 {
    width: 998px;
  }
  .block-nav {
    width: 450px;
  }
  .nav {
    width: 100%;
  }
  .nav a {
    width: 100px;
    font-size: 14px;
  }
  .container-block {
    width: 998px;
  }
  .deconnexion-responsive {
    display: none;
  }
  .deconnexion {
    width: 100px;
  }
  .btn-deconnexion {
    width: 50px;
  }
  .flex-images {
    width: 998px;
  }
  .cadre-image {
    width: 310px;
    height: 180px;
  }
  .containt-user {
    width: 998px;
    height: 450px;
  }
  .block-liste-cherche {
    width: 998px;
    height: 250px;
  }
  .container-lg {
    width: 998px;
  }
  .champ-cherche {
    width: 400px;
  }
  .liste-recherche {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(2,auto);
    grid-template-rows: repeat(2,auto);
    gap: 20px;
  }
  .responsive-1200 {
    display: none;
  }
  .button-modif-action {
    width: 620px;
  }
  .button-modif-xxl {
    width: 998px;
  }
  .table {
    width: 978px;
  }
  .block-liste-membre {
    height: 888px;
  }
  .liste-membre {
    height: 950px;
  }
  .table-membre {
    width: 100%;
    height: 950px;
  }
  .data {
    width: 978px;
    height: 250px;
  }
  .section {
    height: 300px;
  }
  .data-containte {
    width: 938px;
    height: 170px;
  }
  .data-containte-section {
    height: 300px;
  }
  .dataBox {
    display: grid;
    grid-template-columns: repeat(2,350px);
    grid-template-rows: repeat(3,50px);
    gap: 10px;
  }
  .dataBox-sm {
    display: grid;
    grid-template-columns: repeat(2,350px);
    grid-template-rows: repeat(4,40px);
    gap: 8px;
  }
  .block-liste-cherche-lg {
    height: auto;
  }
  .block-liste-cherche-lg form {
    height: auto;
    padding: 30px;
  }
  .liste-recherche-cotisation {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1,auto);
    grid-template-rows: repeat(3,auto);
    gap: 20px;
  }
  .champ-cherche {
    width: 100%;
    height: auto;
  }
  .champ-cherche div {
    width: 350px;
    /* margin-top: -5px; */
  }
  .liste-recherche-px {
    width: 100%;
  }
  .block-liste-xsm {
    height: auto;
  }
  .liste-data-culum {
    height: auto;
    padding: 25px 30px;
  }
  .liste-data-culum-grid {
    display: grid;
    grid-template-columns: repeat(2,350px);
    grid-template-rows: repeat(2,200px);
    gap: 30px;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 998px) {
  .nav-bar {
    width: 768px;
  }
  .bar-1200 {
    width: 768px;
  }
  .block-nav {
    width: 500px;
    position: relative;
  }
  .nav {
    position: absolute;
    width: 50px;
    right: 0;
  }
  .menu-responsive {
    display: none;
  }
  .menu-responsive a {
    display: none;
  }
  .burger-menu {
    display: block;
  }
  .menu-icon {
    display: flex;
  }
  .menu-responsive {
    display: none;
  }
  .menu-responsive a {
    display: none;
  }
  .bg-image {
    height: 600px;
  }
  .container-block {
    width: 768px;
  }
  .verset {
    margin-top: 450px;
  }
  .flex-images {
    width: 768px;
  }
  .cadre-image {
    width: 250px;
    height: 150px;
  }
  .containt-user {
    width: 768px;
    height: 450px;
  }
  .responsive-998 {
    display: none;
  }
  .block-liste-cherche {
    width: 768px;
    height: auto;
  }
  .container-lg {
    width: 768px;
  }
  .champ-cherche {
    width: 350px;
    height: auto;
  }
  .button-modif-action {
    width: 620px;
  }
  .button-modif-xxl {
    width: 768px;
  }
  .table {
    width: 748px;
  }
  .data {
    width: 753px;
    padding: 15px;
  }
  .data-containte {
    width: 728px;
    height: 170px;
  }
  .col-lg-6 {
    width: 320px;
  }
  .col-6 {
    width: 320px;
  }
  .block-liste-cherche-lg {
    height: auto;
  }
  .block-liste-cherche-lg form {
    height: auto;
    padding: 30px;
  }
  .liste-recherche {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1,auto);
    grid-template-rows: repeat(3,auto);
    gap: 20px;
  }
  .liste-recherche-cotisation {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1,auto);
    grid-template-rows: repeat(3,auto);
    gap: 20px;
  }
  .champ-cherche {
    width: 100%;
    height: auto;
  }
  .champ-cherche div {
    width: 100%;
    /* margin-top: -15px; */
  }
  .input-form-search-responsive {
    width: 95%;
  }
  .liste-data-culum-grid {
    grid-template-columns: repeat(2,310px);
  }
  .w-420 {
    width: 100%;
  }
  .mini-input {
    width: 150px;
  }
  .A4pdf1 {
    width: 100%;
    height: calc(700px * 1.414);
    padding: 25px;
  }
  .header_recu {
    height: auto;
    margin-top: 0;
  }
  .cerfa {
    width: 50px;
    height: 27px;
  }
  .number_recu h2 {
    font-size: 12px;
  }
  .number_recu p {
    font-size: 12px;
    margin-top: -30px;
  }
  .title_recu {
    padding: 0 10px;
  }
  .title_recu h1 {
    font-size: 14px;
  }
  .title_recu p {
    font-size: 10px;
  }
  .body_recu {
    margin: 25px 0;
  }
  .objet {
    font-size: 15px;
  }
  .body_recu h2 {
    font-size: 15px;
  }
  .body_recu p {
    font-size: 12px;
  }
  .body_recu h3 {
    font-size: 12px;
    margin-top: 10px;
  }
  div .input-impot {
    font-size: 15px;
    height: 30px;
    width: 120px;
  }
  .signature {
    height: auto;
    margin-top: 30px;
  }
  .A4pdf1 p {
    font-size: 12px;
  }
  .case {
    width: 10px;
    height: 10px;
  }
  .bar-mini-text {
    margin: 15px 0;
  }
  .donateur {
    font-size: 15px;
  }
  .donateur-p {
    margin: 20px 0;
  }
  .align-case {
    height: 35px;
  }
  .paren-align-case {
    height: auto;
    margin: 15px 0;
  }
  .bloc-signature {
    width: auto;
  }
  .bloc-signature h3, p {
    font-size: 12px;
  }
  .img-signature {
    width: 100px;
    height: 60px;
  }
  .data-containte-user-section {
    height: auto;
  }
  .data-containte-user-data {
    height: auto;
  }
}

@media (max-width: 768px) {
  .responsive-768 {
    display: none;
  }
  .nav-bar {
    width: 600px;
  }
  .bar-1200 {
    width: 600px;
  }
  .block-nav {
    width: 400px;
  }
  .container-block {
    width: 600px;
  }
  .nav {
    width: 50px;
  }
  .bg-image {
    height: 450px;
  }
  .container-block {
    width: 600px;
  }
  .verset {
    margin-top: 300px;
  }
  .flex-images {
    display: block;
    width: 600px;
  }
  .cadre-image {
    width: 100%;
    margin-top: 10px;
    height: 250px;
  }
  .containt-user {
    width: 600px;
    margin-top: 0;
    height: auto;
    display: block;
  }
  .photo {
    width: 600px;
    height: 400px;
  }
  .data-user {
    width: 600px;
    padding: 20px;
    height: auto;
  }
  .userphoto {
    width: 100%;
    height: 350px;
  }
  .block-liste-cherche form {
    display: block;
  }
  .block-liste-cherche {
    width: 600px;
    height: 350px;
  }
  .container-lg {
    width: 600px;
  }
  .liste-recherche {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1,auto);
    grid-template-rows: repeat(3,auto);
    gap: 20px;
  }
  .champ-cherche {
    width: 100%;
    height: auto;
  }
  .champ-cherche div {
    width: 100%;
    /* margin-top: -15px; */
  }
  .button-modif-action {
    width: 600px;
    display: flex;
    justify-content: space-between;
  }
  .button-modif-xxl {
    width: 600px;
  }
  .table {
    width: 580px;
  }
  .btn-param {
    width: 140px;
  }
  .block-liste-membre {
    height: 1360px;
  }
  .liste-membre {
    height: 1305px;
  }
  .table-membre {
    width: 100%;
    height: auto;
    padding: 0 8px;
  }
  .data {
    width: 100%;
    height: 360px;
    margin: 5px 0;
  }
  .section {
    height: 550px;
  }
  .data-containte {
    width: 100%;
    height: 170px;
  }
  .data-containte-section {
    height: 550px;
  }
  .dataBox {
    display: grid;
    grid-template-columns: repeat(1,100%);
    grid-template-rows: repeat(5,50px);
    gap: 10px;
  }
  .dataBox-sm {
    display: grid;
    grid-template-columns: repeat(1,100%);
    grid-template-rows: repeat(10,40px);
    gap: 8px;
  }
  .w-small {
    width: 250px;
  }
  .champ {
    width: 100%;
  }
  .input-data {
    width: 200px;
  }
  .col-6 {
    width: 230px;
  }
  .container-form-section {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .block-liste-cherche {
    height: auto;
  }
   .block-liste-cherche-lg {
    height: auto;
  }
  .block-liste-cherche-lg form {
    height: auto;
    padding: 30px;
  }
  .liste-recherche-cotisation {
    height: auto;
  }
  .champ-cherche-sm div {
    width: 100%;
    margin-top: -10px;
    margin-left: -3px;
  }
  .liste-recherche-px .champ-cherche {
    width: 100%;
  }
  .champ-cherche-4 {
    width: 230px;
  }
  .champ-cherche-4 .btn-filter {
    width: auto;
  }
  .liste-data-culum-grid {
    grid-template-columns: repeat(1,100%);
    grid-template-rows: repeat(3,auto);
  }
  .col-lg-6 {
    width: 230px;
  }
  .mini-input {
    width: 120px;
    padding: 0 10px 0 0;
  }
  .input-form {
    padding-left: 10px;
  }
  .A4pdf1 {
    width: 100%;
    height: calc(572px * 1.414);
    padding: 15px;
  }
  .A4pdf1 p {
    font-size: 9px;
  }
  .A4pdf1 h2 {
    font-size: 12px;
  }
  .A4pdf1 h1 {
    font-size: 14px;
  }
  .A4pdf1 h3 {
    font-size: 10px;
  }
  .objet {
    font-size: 13px;
  }
  .donateur {
    font-size: 13px;
  }
  .align-case {
    height: 30px;
  }
  .table-membre-users div {
    height: auto;
  }
}

@media (max-width: 600px) {
  .nav-bar {
    width: 400px;
  }
  .bar-1200 {
    width: 400px;
  }
  .container-block {
    width: 480px;
  }
  .block-nav {
    width: 200px;
  }
  .nav {
    width: 50px;
  }
  .flex-images {
    width: 400px;
  }
  .containt-user {
    width: 400px;
    margin-top: 0;
    height: auto;
    display: block;
  }
  .photo {
    width: 400px;
    height: 300px;
  }
  .data-user {
    width: 400px;
    padding: 20px;
    height: auto;
  }
  .userphoto {
    width: 100%;
    height: 260px;
  }
  .container-form {
    width: 400px;
  }
  .block-liste-cherche {
    width: 400px;
    height: auto;
  }
  .container-lg {
    width: 400px;
  }
  .button-modif-action {
    width: 400px;
    height: auto;
    margin: 30px 0;
    display: block;
  }
  .button-modif-xxl {
    width: 400px;
    height: auto;
  }
  .table {
    width: 380px;
  }
  .btn-param {
    width: 140px;
    margin-top: 10px;
    margin-right: 5px;
  }
  .w-300 {
    width: 250;
  }
  .input-data {
    width: 150px;
  }
  .responsive-on-600 {
    display: block;
  }
  .responsive-600 {
    display: none;
  }
  .dataBox-sm-600 {
    display: grid;
    margin-top: 30px;
    grid-template-columns: repeat(1,100%);
    grid-template-rows: repeat(10,30px);
    gap: 10px;
  }
  .row {
    display: block;
    height: auto;
  }
  .col-6 {
    width: 100%;
    margin-top: 20px;
  }
  .container-form-membre {
    height: auto;
  }
  .revoque {
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 5px;
  }
  .blockSection {
    display: block;
    height: auto;
  }
  .champ-cherche {
    display: block;
  }
  .liste-recherche-px {
    display: block;
  }
  .block-liste-cherche-lg {
    height: auto;
  }
  .row-lg {
    display: block;
    height: auto;
    width: 100%;
  }
  .col-lg-6 {
    width: 100%;
  }
  .mini-input {
    width: 200px;
    padding: 0 10px 0 0;
  }
  .container-form-membre {
    width: 100%;
    padding: 20px;
  }
  .btn {
    margin-right: 5px;
  }
  .param-nav {
    width: 100%;
  }
  .pageUTL {
    width: 180px;
  }
  .notUTL {
    width: 180px;
  }
  .A4pdf1 {
    width: 100%;
    height: calc(378px * 1.414);
    padding: 17px;
  }
  .A4pdf1 p {
    font-size: 6px;
    margin-top: 3.5px;
  }
  .A4pdf1 h1 {
    font-size: 11px;
  }
  .A4pdf1 h2 {
    font-size: 9px;
  }
  .A4pdf1 h3 {
    font-size: 7px;
  }
  .objet {
    font-size: 10px;
  }
  .donateur {
    font-size: 10px;
  }
  .align-case {
    height: 25px;
  }
  .case {
    width: 8px;
    height: 8px;
    border-radius: 1px;
  }
  .bar-mini-text {
    margin: 8px 0;
  }
  .body_recu {
    margin: 10px 0;
  }
  .number-recu-p {
    margin: 0;
  }
  div .input-impot {
    width: 100px;
    height: 20px;
    margin: -20px 0 10px 0;
    font-size: 11px;
    border-radius: 3px;
  }
  .mini-text {
    font-size: 6px;
  }
  .paren-align-case {
    margin: 8px;
  }
  .img-signature {
    width: 50px;
    height: 30px;
  }
 }

@media (max-width: 480px) {
  /* Page Login */
  .container-login {
    width: 400px;
    height: 500px;
    padding: 20px;
  }
  .nav-bar {
    width: 400px;
  }
  .block-nav {
    width: 150px;
  }
  .nav {
    width: 50px;
  }
  .container-block {
    width: 350px;
  }
  .flex-images {
    width: 350px;
  }
  .verset {
    margin-top: 250px;
    width: 200px;
  }
  .bar-1200 {
    width: 300px;
  }
  .containt-user {
    width: 300px;
    margin-top: 0;
    height: auto;
    display: block;
  }
  .photo {
    width: 300px;
    height: 300px;
  }
  .data-user {
    width: 300px;
    padding: 20px;
    height: auto;
  }
  .userphoto {
    width: 100%;
    height: 260px;
  }
  .container-form {
    width: 300px;
  }
  .block-liste-cherche {
    width: 400px;
    height: auto;
  }
  .container-lg {
    width: 400px;
  }
  .button-modif-action {
    width: 350px;
    display: block;
  }
  .button-modif-xxl {
    width: 350px;
  }
  .table {
    width: 380;
  }
  .btn-param {
    width: 140px;
    margin-top: 10px;
  }
  .w-300 {
    width: 250;
  }
  .responsive-480 {
    display: none;
  }
  .container-form-membre {
    width: 100%;
    padding: 20px;
  }
  .blockSection {
    display: block;
    height: auto;
  }
  .champ-cherche {
    display: block;
  }
  .liste-recherche-px {
    display: block;
  }
  .block-liste-cherche-lg {
    height: auto;
  }
  .param-nav {
    width: 100%;
  }
  .pageUTL {
    width: 180px;
  }
  .notUTL {
    width: 180px;
  }
  .A4pdf1 {
    width: 100%;
    height: calc(387px * 1.414);
    padding: 20px;
  }
  .A4pdf1 p {
    font-size: 6px;
    margin-top: 3px;
  }
  .A4pdf1 h1 {
    font-size: 10px;
  }
  .A4pdf1 h2 {
    font-size: 7px;
  }
  .A4pdf1 h3 {
    font-size: 6px;
    margin-top: 10px;
  }
  .cerfa {
    width: 40px;
    height: 21px;
  }
  .objet {
    font-size: 8px;
  }
  .donateur {
    font-size: 10px;
  }
  .align-case {
    height: 20px;
  }
  .case {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 0,5px;
  }
  .bar-mini-text {
    margin: 5px 0;
    height: 0.5px;
  }
  .body_recu {
    margin: 20px 0;
  }
  .number-recu-p {
    margin: 0;
  }
  div .input-impot {
    width: 80px;
    height: 18px;
    margin: -10px 0 15px 0;
    font-size: 9px;
    border: 0.3px solid rgb(174, 174, 174);
    border-radius: 3px;
  }
  .mini-text {
    font-size: 5px;
  }
  .paren-align-case {
    margin: 10px 0;
  }
  .img-signature {
    width: 50px;
    height: 30px;
  }
  .signature {
    margin-top: 15px;
  }
  .mini-text-4 {
    margin: 10px 0;
  }
  .donateur {
    font-size: 6px;
  }
  .A4pdf1 .donateur-p {
    margin: 10px 0 5px 0;
  }
 }

@media (max-width: 384px) {
  /* Page Login */
  .container-login {
    width: 320px;
    height: 500px;
    padding: 20px;
  }
  .box-alert {
    width: 320px;
  }
  .block-btn-responsive-380 {
    display: flex;
    justify-content: center;
  }
  .btn-responsive-380 {
    width: 100px;
  }
  .responsive-384 {
    display: none;
  }
  .nav-bar {
    width: 320px;
  }
  .block-nav {
    width: 150px;
  }
  .nav {
    width: 50px;
  }
  .container-block {
    width: 320px;
  }
  .flex-images {
    width: 320px;
  }
  .verset {
    margin-top: 100px;
    width: 200px;
  }
  .bar-1200 {
    width: 300px;
  }
  .block-liste-cherche {
    width: 320px;
    height: auto;
  }
  .container-lg {
    width: 320px;
  }
  .button-modif-action {
    width: 320px;
    display: block;
  }
  .button-modif-xxl {
    width: 320px;
  }
  .table {
    width: 300px;
  }
  .btn-param {
    width: 140px;
    margin-top: 10px;
  }
  .container-form-membre {
    padding: 20px;
  }
  .btn-param {
    margin-right: 5px;
  }
  .blockSection {
    display: block;
    height: auto;
  }
  .champ-cherche {
    display: block;
  }
  .liste-recherche-px {
    display: block;
  }
  .block-liste-cherche-lg {
    height: auto;
  }
  .blockSection {
    display: block;
    height: auto;
  }
  .champ-cherche {
    display: block;
  }
  .liste-recherche-px {
    display: block;
  }
  .block-liste-cherche-lg form {
    padding: 10px;
  }
  .size-384 {
    font-size: 17px;
  }
  .pageUTL {
    width: 150px;
  }
  .notUTL {
    width: 150px;
  }
  .A4pdf1 {
    width: 100%;
    height: calc(284px * 1.414);
    padding: 10px;
  }
  .A4pdf1 p {
    font-size: 4.5px;
    margin-top: 3px;
  }
  .A4pdf1 h1 {
    font-size: 8px;
  }
  .A4pdf1 h2 {
    font-size: 6px;
    margin-top: 5px;
  }
  .A4pdf1 h3 {
    font-size: 5px;
    margin-top: 5px;
  }
  .cerfa {
    width: 40px;
    height: 21px;
  }
  .objet {
    font-size: 7px;
  }
  .donateur {
    font-size: 8px;
  }
  .align-case {
    height: 18px;
  }
  .case {
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 0,5px;
  }
  .bar-mini-text {
    margin: 5px 0;
    height: 0.5px;
  }
  .body_recu {
    margin: 6px 0;
  }
  .number-recu-p {
    margin: 0;
  }
  div .input-impot {
    width: 60px;
    height: 15px;
    margin: -15px 0 10px 0;
    font-size: 9px;
    border: 0.3px solid rgb(174, 174, 174);
    border-radius: 2px;
  }
  .mini-text {
    font-size: 5px;
  }
  .paren-align-case {
    margin: 5px 0;
  }
  .img-signature {
    width: 50px;
    height: 30px;
  }
  .signature {
    margin-top: 10px;
  }
  .mini-text-4 {
    margin: 8px 0;
  }
  .donateur {
    font-size: 6px;
  }
  .A4pdf1 .donateur-p {
    margin: 5px 0 5px 0;
  }
}
